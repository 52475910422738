import React, {Fragment, useEffect} from 'react';
import {injectIntl} from 'react-intl'
import LoginBase from "./loginBase";
import {useAuthDispatch} from "../../context";
import {SET_PROFILE} from "../../constants/ActionType";
import {initialProfile} from "../../context/reducer";


function Logout(props) {
  const dispatch = useAuthDispatch()

  useEffect(() => {
    dispatch({type: SET_PROFILE, payload: initialProfile});
    localStorage.setItem('currentProfile', JSON.stringify(initialProfile));
  }, []);

  return (
    <Fragment>
      <LoginBase
        intl={props.intl}
        logout={true}
      />
    </Fragment>
  )
}

export default injectIntl(Logout)